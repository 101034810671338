<template>
    <form class="ui prescription form" :class="{loading: saving}" @submit.prevent="savePrescription">
        <div class="ui middle aligned grid">
            <div class="sixteen wide mobile eight wide tablet eight wide computer column">
                <h3 class="ui header">
                    Treatment Prescription
                    <template v-if="customer && customer.name">
                        <div class="ui fitted hidden divider"></div>
                        <div class="sub header"><strong class="ui purple text">{{customer.name}}</strong></div>
                    </template>
                </h3>
            </div>
            <div class="computer only tablet only right aligned eight wide column">
                <img class="logo" src="~@/assets/images/logo/1991spwg.png"/>
            </div>
        </div>

        <div class="ui hidden divider"></div>

        <div class="fields" :class="{two: isAdmin}">
            <div class="required field" :class="{error: form_error.main_treatment, 'sixteen wide': !isAdmin}">
                <label>Main Treatment</label>
                <div ref="maintreatmentdropdown" class="ui search selection dropdown">
                    <input type="hidden" :value="form.main_treatment_code" @change="changeMainTreatment($event.target.value)"/>
                    <i class="dropdown icon"></i>
                    <div class="default text">&nbsp;</div>
                    <div class="menu">
                        <div class="item" v-for="main_treatment in treatments.main" :key="`main_treatment_option_${main_treatment.code}`" :data-value="main_treatment.code">
                            <strong>{{main_treatment.code}}</strong> {{main_treatment.description}}
                        </div>
                    </div>
                </div>
                <div class="ui error small message"><i class="info circle icon"></i> Please select a main treatment to continue.</div>
            </div>

            <div class="field" v-if="isAdmin">
                <label>Prescription Date / Time</label>
                <input type="datetime-local" :max="(new Date())|formatdate('YYYY-MM-DDTHH:mm')" v-model="prescription_datetime"/>
            </div>
        </div>

        <div class="two fields">
            <div class="field">
                <label>Additional Treatment(s)</label>
                <div ref="addontreatmentdropdown" class="ui clearable multiple search selection dropdown">
                    <input type="hidden" @change="changeAddonTreatment($event.target.value)"/>
                    <i class="dropdown icon"></i>
                    <div class="default text">Optional</div>
                    <div class="menu">
                        <div class="item" v-for="addon_treatment in treatments.addon" :key="`addon_treatment_option_${addon_treatment.code}`" :data-value="addon_treatment.code">
                            <strong>{{addon_treatment.code}}</strong> {{addon_treatment.description}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="field">
                <label>Machine Treatment(s)</label>
                <div ref="machinetreatmentdropdown" class="ui clearable multiple search selection dropdown">
                    <input type="hidden" @change="changeMachineTreatment($event.target.value)"/>
                    <i class="dropdown icon"></i>
                    <div class="default text">Optional</div>
                    <div class="menu">
                        <div class="item" v-for="machine_treatment in treatments.machine" :key="`machine_treatment_option_${machine_treatment.code}`" :data-value="machine_treatment.code">
                            <strong>{{machine_treatment.code}}</strong> {{machine_treatment.description}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="required field" :class="{error: form_error.note}">
            <label>Consultation Notes</label>
            <note-editor v-model.trim="form.note"/>
            <div class="ui error small message"><i class="info circle icon"></i> Please complete the consultation notes to continue.</div>
        </div>
    </form>
</template>

<script>
import invert from "lodash.invert";
import TreatmentType from "@/assets/constants/treatmentType";

import NoteEditor from "@/components/NoteEditor";

export default {
    name: "prescription-form",
    components: {
        NoteEditor
    },
    props: {
        prescription: {
            type: Object
        },
        customer: {
            type: Object
        }
    },
    data() {
        return {
            treatments: {
                main: [],
                addon: [],
                machine: []
            },
            form: {
            },
            form_error: {
                main_treatment: false,
                note: false
            },
            saving: false
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.$http.get("treatments", {
                params: {
                    filter: JSON.stringify({
                        type: invert(TreatmentType)[TreatmentType.MAIN],
                        active: true
                    })
                }
            }).then((response) => {
                this.treatments.main = response.data;
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                $(this.$refs.maintreatmentdropdown).dropdown({
                    fullTextSearch: true
                });
            });

            this.$http.get("treatments", {
                params: {
                    filter: JSON.stringify({
                        type: invert(TreatmentType)[TreatmentType.ADDON],
                        active: true
                    })
                }
            }).then((response) => {
                this.treatments.addon = response.data;
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                $(this.$refs.addontreatmentdropdown).dropdown({
                    fullTextSearch: true
                });
            });

            this.$http.get("treatments", {
                params: {
                    filter: JSON.stringify({
                        type: invert(TreatmentType)[TreatmentType.MACHINE],
                        active: true
                    })
                }
            }).then((response) => {
                this.treatments.machine = response.data;
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                $(this.$refs.machinetreatmentdropdown).dropdown({
                    fullTextSearch: true
                });
            });
        });
    },
    computed: {
        prescription_datetime: {
            get() {
                let prescription_datetime = "";
                if(this.form?.prescription_datetime) {
                    prescription_datetime = this.$moment(this.form.prescription_datetime).format("YYYY-MM-DDTHH:mm");
                }
                return prescription_datetime;
            },
            set(prescription_datetime) {
                this.form.prescription_datetime = prescription_datetime;
            }
        }
    },
    methods: {
        isNoteEmpty() {
            let $div = $("<div/>").html(this.form.note);
            return !$div.text().trim();
        },
        savePrescription() {
            if(this.saving) {
                return;
            }

            let form = Object.assign({}, this.form);

            //let's validate
            if(!form.main_treatment_code || !form.main_treatment_description) {
                this.form_error.main_treatment = true;
                return;
            }

            if(this.isNoteEmpty()) {
                this.form_error.note = true;
                return;
            }

            this.saving = true;
            if(this.prescription?.id) {
                this.$http.put(`prescriptions/${this.prescription.id}`, form).then((response) => {
                    this.$toasted.success("Prescription updated successfully.", {
                        duration: 3000
                    });

                    this.$emit("save:prescription", response.data);
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Failed to update prescription.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                this.$http.post("prescriptions", form).then((response) => {
                    this.$toasted.success("Prescription saved successfully.", {
                        duration: 3000
                    });

                    this.$emit("save:prescription", response.data);
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Failed to save prescription.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            }
        },
        changeMainTreatment(main_treatment_code) {
            const main_treatment = this.treatments.main.find((main_treatment) => {
                return main_treatment.code === main_treatment_code;
            }) || {
                code: main_treatment_code,
                description: main_treatment_code
            };

            this.form.main_treatment_code = main_treatment.code;
            this.form.main_treatment_description = main_treatment.description;

            this.form_error.main_treatment = false;
        },
        changeAddonTreatment(addon_treatment_codes) {
            let addon_treatments = [];
            if(addon_treatment_codes) {
                addon_treatments = addon_treatment_codes.split(",").map((addon_treatment_code) => {
                    const { code, description } = this.treatments.addon.find((addon_treatment) => {
                        return addon_treatment.code === addon_treatment_code;
                    }) || {
                        code: addon_treatment_code,
                        description: addon_treatment_code
                    };

                    return {
                        code,
                        description
                    };
                });
            }

            this.form.addon_treatments = addon_treatments;
        },
        changeMachineTreatment(machine_treatment_codes) {
            let machine_treatments = [];
            if(machine_treatment_codes) {
                machine_treatments = machine_treatment_codes.split(",").map((machine_treatment_code) => {
                    const { code, description } = this.treatments.machine.find((machine_treatment) => {
                        return machine_treatment.code === machine_treatment_code;
                    }) || {
                        code: machine_treatment_code,
                        description: machine_treatment_code
                    };

                    return {
                        code,
                        description
                    };
                });
            }

            this.form.machine_treatments = machine_treatments;
        }
    },
    watch: {
        prescription: {
            deep: true,
            immediate: true,
            handler() {
                this.form = Object.assign({}, !this.isBlankObject(this.prescription)? this.prescription : {
                    main_treatment_code: "",
                    main_treatment_description: "",
                    addon_treatments: [],
                    machine_treatments: [],
                    note: ""
                });

                this.$nextTick(() => {
                    if(this.form.main_treatment_code) {
                        $(this.$refs.maintreatmentdropdown).dropdown("set selected", this.form.main_treatment_code);
                    } else {
                        $(this.$refs.maintreatmentdropdown).dropdown("clear");
                    }

                    if(this.form.addon_treatments && this.form.addon_treatments.length) {
                        $(this.$refs.addontreatmentdropdown).dropdown("set exactly", this.form.addon_treatments.map((addon_treatment) => {
                            return addon_treatment.code;
                        }));
                    } else {
                        $(this.$refs.addontreatmentdropdown).dropdown("clear");
                    }

                    if(this.form.machine_treatments && this.form.machine_treatments.length) {
                        $(this.$refs.machinetreatmentdropdown).dropdown("set exactly", this.form.machine_treatments.map((machine_treatment) => {
                            return machine_treatment.code;
                        }));
                    } else {
                        $(this.$refs.machinetreatmentdropdown).dropdown("clear");
                    }
                });
            }
        },
        "form.note"() {
            if(!this.isNoteEmpty()) {
                if(this.form_error.note) {
                    this.form_error.note = false;
                }
            }
        },
        saving() {
            this.$emit("change:saving", this.saving);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/consultationform.scss";

.ui.prescription.form {
    .ui.multiple.dropdown {
        ::v-deep {
            input.search {
                height: auto;
            }
        }
    }
}
</style>