<template>
    <div>
        <div class="ui editor segments">
            <div class="ui toolbar secondary segment" v-if="editor">
                <div>
                    <button class="ui compact icon button" :class="editor.isActive('paragraph')? 'black' : 'alt'" type="button" title="Paragraph" @click="editor.chain().focus().setParagraph().run()">
                        <i class="ri-paragraph icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('heading', { level: 1 })? 'black' : 'alt'" type="button" title="Heading 1" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()">
                        <i class="ri-h-1 icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('heading', { level: 2 })? 'black' : 'alt'" type="button" title="Heading 2" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
                        <i class="ri-h-2 icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('heading', { level: 3 })? 'black' : 'alt'" type="button" title="Heading 3" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
                        <i class="ri-h-3 icon"></i>
                    </button>
                    <!-- <button class="ui compact icon button" :class="editor.isActive('heading', { level: 4 })? 'black' : 'alt'" type="button" title="Heading 4" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()">
                        <i class="ri-h-4 icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('heading', { level: 5 })? 'black' : 'alt'" type="button" title="Heading 5" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()">
                        <i class="ri-h-5 icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('heading', { level: 6 })? 'black' : 'alt'" type="button" title="Heading 6" @click="editor.chain().focus().toggleHeading({ level: 6 }).run()">
                        <i class="ri-h-6 icon"></i>
                    </button> -->
                    <button class="ui compact icon button" :class="editor.isActive('bulletList')? 'black' : 'alt'" type="button" title="Bullet List" @click="editor.chain().focus().toggleBulletList().run()">
                        <i class="ri-list-unordered icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('orderedList')? 'black' : 'alt'" type="button" title="Ordered List" @click="editor.chain().focus().toggleOrderedList().run()">
                        <i class="ri-list-ordered icon"></i>
                    </button>
                    |
                    <button class="ui compact icon button" :class="editor.isActive('bold')? 'black' : 'alt'" type="button" title="Bold" @click="editor.chain().focus().toggleBold().run()">
                        <i class="ri-bold icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('italic')? 'black' : 'alt'" type="button" title="Italic" @click="editor.chain().focus().toggleItalic().run()">
                        <i class="ri-italic icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('strike')? 'black' : 'alt'" type="button" title="Strike" @click="editor.chain().focus().toggleStrike().run()">
                        <i class="ri-strikethrough icon"></i>
                    </button>
                    <!-- <button class="ui compact icon button" :class="editor.isActive('code')? 'black' : 'alt'" type="button" title="Code" @click="editor.chain().focus().toggleCode().run()">
                        <i class="ri-code-view icon"></i>
                    </button> -->
                    |
                    <button class="ui compact icon button" :class="editor.isActive({ textAlign: 'left' })? 'black' : 'alt'" type="button" title="Left" @click="editor.chain().focus().setTextAlign('left').run()">
                        <i class="ri-align-left icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive({ textAlign: 'center' })? 'black' : 'alt'" type="button" title="Center" @click="editor.chain().focus().setTextAlign('center').run()">
                        <i class="ri-align-center icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive({ textAlign: 'right' })? 'black' : 'alt'" type="button" title="Right" @click="editor.chain().focus().setTextAlign('right').run()">
                        <i class="ri-align-right icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive({ textAlign: 'justify' })? 'black' : 'alt'" type="button" title="Left" @click="editor.chain().focus().setTextAlign('justify').run()">
                        <i class="ri-align-justify icon"></i>
                    </button>
                </div>
                <div class="ui hidden fitted divider"></div><div class="ui hidden fitted divider"></div>
                <div>
                    <button  class="ui compact icon button" :class="editor.isActive('highlight')? 'black' : 'alt'" type="button" title="Highlight" @click="editor.chain().focus().toggleHighlight().run()">
                        <i class="ri-mark-pen-line icon"></i>
                    </button>
                    <button class="ui compact icon button" :class="editor.isActive('blockquote')? 'black' : 'alt'" type="button" title="Blockquote" @click="editor.chain().focus().toggleBlockquote().run()">
                        <i class="ri-double-quotes-l icon"></i>
                    </button>
                    <button class="ui compact icon alt button" type="button" title="Horizontal Rule" @click="editor.chain().focus().setHorizontalRule().run()">
                        <i class="ri-separator icon"></i>
                    </button>
                    <button class="ui compact icon alt button" type="button" title="Hard Break" @click="editor.chain().focus().setHardBreak().run()">
                        <i class="ri-text-wrap icon"></i>
                    </button>
                    |
                    <button class="ui compact icon alt button" type="button" title="Clear Format"  @click="editor.chain().focus().unsetAllMarks().clearNodes().run()">
                        <i class="ri-format-clear icon"></i>
                    </button>
                    <button class="ui compact icon alt button" type="button" title="Undo" @click="editor.chain().focus().undo().run()">
                        <i class="ri-arrow-go-back-line icon"></i>
                    </button>
                    <button class="ui compact icon alt button" type="button" title="Redo" @click="editor.chain().focus().redo().run()">
                        <i class="ri-arrow-go-forward-line icon"></i>
                    </button>
                </div>
                <!-- <button @click="editor.chain().focus().unsetAllMarks().run()">
                    clear marks
                </button>
                <button @click="editor.chain().focus().clearNodes().run()">
                    clear nodes
                </button> -->


                <!-- <button class="ui compact icon button" :class="editor.isActive('codeBlock')? 'black' : 'alt'" type="button" title="Code Block" @click="editor.chain().focus().toggleCodeBlock().run()">
                    <i class="ri-code-box-line icon"></i>
                </button> -->

            </div>
            <editor-content class="ui content segment" :editor="editor"/>
        </div>
    </div>
</template>

<script>
import "remixicon/fonts/remixicon.css";

import { Editor, EditorContent } from "@tiptap/vue-2";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";

export default {
    components: {
        EditorContent,
    },
    props: {
        value: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            editor: null
        };
    },
    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value;

            if(isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        }
    },
    mounted() {
        this.editor = new Editor({
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: "Type consultation note here...",
                }),
                TextAlign.configure({
                    types: ["heading", "paragraph"],
                }),
                Highlight
            ],
            content: this.modelValue,
            onUpdate: () => {
                this.$emit("input", this.editor.getHTML());
            }
        });
    },
    beforeDestroy() {
        this.editor.destroy();
    }
};
</script>

<style lang="scss" scoped>
.ui.toolbar.segment {
    padding: 0.5em;
}

.ui.content.segment {
    ::v-deep {
        .ProseMirror {
            outline: none;
            min-height: 7em;

            p.is-editor-empty:first-child::before {
                content: attr(data-placeholder);
                float: left;
                color: #adb5bd;
                pointer-events: none;
                height: 0;
            }
        }
    }
}
</style>